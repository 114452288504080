import { PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { RouteComponentProps } from "react-router";

interface Params {
  id: string;
}
const ProductForm: React.FC<RouteComponentProps<Params>> = (props) => {
  console.log(props);
  return (
    <div>Product Form</div>
    // <div className="app-content">
    //   <PageHeader title="Product Form" />
    //   <Content>Product Form : {props.match.params.id} </Content>
    // </div>
  );
};

export default ProductForm;
