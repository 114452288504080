import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Col, Form, Input, message, Row, Typography } from "antd";

import teacherService from "../../services/teacher/teacherService";
import { Teacher } from "src/domain/teacherType";
import { showMessage } from "src/utils";

interface IProps {
  id: number;
  trigger: any;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const TeacherForm: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  const [teacher, setTeacher] = useState<Teacher>({
    id: 0,
    name: "",
    lastname: "",
    gender: "",
    education: ""
  });
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    loadTeacher();
    return () => {};
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: teacher.name,
      lastname: teacher.lastname,
      gender: teacher.gender,
      education: teacher.education,
    });
    return () => {};
  }, [teacher]);

  const loadTeacher = async () => {
    const res = await teacherService.getTeacherById(id);
    if (res.length === 0) {
        setTeacher({
            id: 0,
            name: "",
            lastname: "",
            gender: "",
            education: ""
          })
    } else {
        setTeacher(res[0]);
    }

    
  };

  const onFinish = async (values: any) => {
     console.log("Succes :", values);
    const _teacher: Teacher = {
      id: id,
      name: values.name,
      lastname: values.lastname,
      gender: values.gender,
      education: values.education,
    };

    if (id === 0) {
      try {
        const res = await teacherService.addTeacher(_teacher);
        console.log(res);
        history.replace("/app/teacher/list");
        message.success("New teacher Complete!", 1.5);
        setTrigger(false);
      } catch (err) {
        showMessage("Something went wrong!");
        setTrigger(false);
      }
    } else if (id !== 0) {
      try {
        const res = await teacherService.updateTeacher(_teacher);
        history.replace("/app/teacher/view/" + id);
        message.success("Update Complete!", 1.5);
        setTrigger(false);
      } catch (err) {
        showMessage("Something went wrong!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
      <Form
        {...layout}
        form = {form} 
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row style={{ marginBottom: "3em" }}>
          <Col span={24} style={{ marginTop: "2em" }}>
            <Form.Item
              wrapperCol={{ offset: 4, span: 8 }}
              // rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Typography.Title level={3}>General Information</Typography.Title>
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input your name!" },
                {
                  min: 5,
                  max: 50,
                  message: "Please input your name between 5 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                { required: true, message: "Please input your lastname!" },
                {
                  min: 5,
                  max: 50,
                  message: "Please input your name between 5 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Gender"
              name="gender"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Education"
              name="education"
            >
              <Input />
            </Form.Item>

          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TeacherForm;
