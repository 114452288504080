import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Col, Form, Input, message, Row, Typography } from "antd";

import { Post } from "src/domain/postType";
import postService from "src/services/postService";
import TextArea from "antd/lib/input/TextArea";
import { showMessage } from "src/utils";

interface IProps {
  id: number;
  trigger: any;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const PostForm: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  const [post, setPost] = useState<Post>({} as Post);

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({
      title: post.title,
      body: post.body,
    });
  }, [post]);

  useEffect(() => {
    (async () => {
      const res = await postService.getPostById(id);
      setPost(res.data);
    })();
  }, []);

  const onFinish = async (values: any) => {
    // console.log("Succes :", values);
    const post_: Post = {
      id: id,
      userId: post.userId,
      title: post.title,
      body: post.body,
    };
    if (id === 0) {
      try {
        const res = await postService.newPost(post_);
        console.log(res);
        history.replace("/app/posts/list");
        message.success("New post complete!", 1.5);
        setTrigger(false);
      } catch (err) {
        showMessage("Something went wrong!");
        setTrigger(false);
      }
    } else if (id !== 0) {
      try {
        const res = await postService.updatePost(post_);
        console.log(res.data);
        history.replace("/app/posts/list");
        message.success("Upate post complete!", 1.5);
        setTrigger(false);
      } catch (err) {
        showMessage("Something went wrong!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row style={{ marginBottom: "3em" }}>
          <Col span={24} style={{ marginTop: "2em" }}>
            <Form.Item
              wrapperCol={{ offset: 6, span: 12 }}
              // rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Typography.Title level={3}>Post Information</Typography.Title>
            </Form.Item>

            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true, message: "Please input your title!" },
                {
                  min: 5,
                  max: 100,
                  message: "Please input your title at least 5 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Body"
              name="body"
              rules={[
                { required: true, message: "Please input your body!" },
                {
                  min: 5,
                  message: "Please input your body at least 5 character",
                },
              ]}
            >
              <TextArea rows={5} autoSize />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PostForm;
