import React from "react";

type justifyType = "center" | "start" | "end" | "between" | "around";
type alignItemsType = "center" | "start" | "end";
type flexDirectionType = "row" | "column";

interface IProps {
  className?: string;
  alignItems?: alignItemsType;
  justifyContent?: justifyType;
  flexDirection?: flexDirectionType;
  children?: React.ReactNode;
}

const Flex: React.FC<IProps> = ({
  className,
  alignItems,
  justifyContent,
  flexDirection = "row",
  children,
}) => {
  return (
    <div
      className={`d-flex ${className ? className : ""} ${
        flexDirection ? "flex-" + flexDirection : ""
      } ${alignItems ? "align-items-" + alignItems : ""} ${
        justifyContent ? "justify-content-" + justifyContent : ""
      }`}
      style={{ width: "100%" }}
    >
      {children}
    </div>
  );
};

export default Flex;
