import React, { useState, useEffect } from "react";

import { Button, Descriptions, Spin } from "antd";

import { useHistory } from "react-router-dom";

import PageHeader from "src/layouts/PageHeader";
import { EditOutlined } from "@ant-design/icons";
import postService from "src/services/postService";
import { RouteComponentProps } from "react-router";
import { Post } from "src/domain/postType";
import { Content } from "antd/lib/layout/layout";

interface IParams {
  id: string;
}

const users = [
  { id: 1, name: "Johny Dave" },
  { id: 2, name: "John Doe" },
  { id: 3, name: "The Rock" },
  { id: 4, name: "Na-dech Cumiya" },
  { id: 5, name: "Yaya Urusya" },
];

const ViewPost: React.FC<RouteComponentProps<IParams>> = (props) => {
  //   console.log(props);
  const [post, setPost] = useState<Post>({} as Post);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    let id: number = Number(props.match.params.id);
    (async () => {
      setLoading(true);
      const res = await postService.getPostById(id);
      //   console.log(res);
      const { id: _id, userId, title, body } = res.data;
      let x: any = users.filter((item: any) => item.id === userId);
      const { name } = x[0];

      let y: Post = {
        id: _id,
        userId,
        title,
        body,
        userName: name,
      };
      setPost(y);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "20%",
          }}
        >
          <Spin size="large" tip="Loading..." />
        </div>
      ) : (
        <>
          <PageHeader
            title={`Post : ${post.id}`}
            onClose={() => history.goBack()}
          >
            <Button
              size="small"
              //   type="primary"
              icon={<EditOutlined />}
              onClick={() => history.push("/app/posts/edit/" + post.id)}
            >
              Edit
            </Button>
          </PageHeader>
          <Content className="app-page-content">
            <div style={{ padding: "0px 20px" }}>
              <Descriptions
                style={{ marginTop: "20px" }}
                column={1}
                title="Post Information"
                size="middle"
                bordered
                labelStyle={{ width: 100, fontWeight: "bold" }}
              >
                <Descriptions.Item label="Id">{post.id}</Descriptions.Item>
                <Descriptions.Item label="Name">
                  {post.userName}
                </Descriptions.Item>
                <Descriptions.Item label="Title">
                  {post.title}
                </Descriptions.Item>
                <Descriptions.Item label="Body">{post.body}</Descriptions.Item>
              </Descriptions>
            </div>
          </Content>
        </>
      )}
    </>
  );
};

export default ViewPost;
