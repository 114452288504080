import React, { useState, useEffect } from "react";
import { Button, Divider, Table, Tag } from "antd";
import PageHeader from "src/layouts/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";

import userService from "../../services/userService";
import { User } from "src/domain/userType";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: string, item: User, index: number) => (
      <Link to={`/app/users/view/${item.id}`}>{value}</Link>
    ),
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  // {
  //   title: "Tags",
  //   key: "tags",
  //   dataIndex: "tags",
  //   render: (tags: any) => (
  //     <span>
  //       {tags.map((tag: any) => {
  //         let color = tag.length > 5 ? "geekblue" : "green";
  //         if (tag === "loser") {
  //           color = "volcano";
  //         }
  //         return (
  //           <Tag color={color} key={tag}>
  //             {tag.toUpperCase()}
  //           </Tag>
  //         );
  //       })}
  //     </span>
  //   ),
  // },
  // {
  //   title: "Action",
  //   key: "action",
  //   render: (text: any, record: any) => (
  //     <span>
  //       <a>Invite {record.name}</a>
  //       <Divider type="vertical" />
  //       <a>Delete</a>
  //     </span>
  //   ),
  // },
];

const UserLists: React.FC<User> = () => {
  const [users, setUsers] = useState<User[]>([]);
  const history = useHistory();

  useEffect(() => {
    userService
      .getUsers()
      .then((res) => {
        console.log(res.data);
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {};
  }, []);

  return (
    <>
      <PageHeader title="User List">
        <Button
          size="small"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/users/new")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div>
          <Table
            columns={columns}
            dataSource={users}
            size="small"
            rowKey={(item: User) => item.name}
          />
        </div>
      </Content>
    </>
  );
};

export default UserLists;
