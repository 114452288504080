import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { Button, Descriptions, Space, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import officerService from "../../services/officerService";
import { Officer } from "src/domain/officerType";

interface IParams {
  id: string;
}

const ViewOfficer: React.FC<RouteComponentProps<IParams>> = (props) => {
  const [data, setData] = useState<Officer>({} as Officer);
  const history = useHistory();

  useEffect(() => {
    let id: number = Number(props.match.params.id);
    let x: any = officerService.getOfficerById(id);
    setData(x);
  }, []);

  const handleDelete = (id: number) => {
    officerService.deleteOfficer(id);
    history.goBack();
  };

  const confirm = (e: any) => {
    console.log(e);
    message.success("Delete successfully");
    handleDelete(Number(data.id));
  };

  const cancel = (e: any) => {
    console.log(e);
    // message.error("Click on No");
  };

  return (
    <>
      <PageHeader
        title={"View officer"}
        onClose={() => history.push("/app/officers")}
      >
        <Space>
          <Button
            size="small"
            // type="default"
            icon={<EditOutlined />}
            onClick={() => history.push(`/app/officers/edit/${data.id}`)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this officer?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              type="primary"
              danger
              icon={<DeleteOutlined />}
              // onClick={() => handleDelete(Number(data.id))}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      </PageHeader>
      <Content className="app-page-content">
        <div style={{ padding: "0px 20px" }}>
          <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            // title="Genral Information"
            size="middle"
            labelStyle={{ width: 150, fontWeight: "bold" }}
            bordered
          >
            <Descriptions.Item
              label="ID Card :"
              span={2}
              labelStyle={{ textAlign: "right" }}
            >
              {data.idCard}
            </Descriptions.Item>
            <Descriptions.Item
              label="FirstName :"
              labelStyle={{ textAlign: "right" }}
            >
              {data.firstname}
            </Descriptions.Item>
            <Descriptions.Item
              label="Lastname :"
              labelStyle={{ textAlign: "right" }}
            >
              {data.lastname}
            </Descriptions.Item>
            <Descriptions.Item
              label="Address :"
              labelStyle={{ textAlign: "right" }}
            >
              {data.address}
            </Descriptions.Item>
            <Descriptions.Item
              label="Position :"
              labelStyle={{ textAlign: "right" }}
            >
              {data.position}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Content>
    </>
  );
};

export default ViewOfficer;
