import http from "../lib/http";

const url = "https://jsonplaceholder.typicode.com/posts";
const getPosts = () => {
    return http.get(url);
};

const getPostById = (id: number) => {
    return http.get(url+"/"+id)
};

const updatePost = (post: any) => {
    return http.put(url+"/"+post.id, post);
};

const newPost = (post: any) => {
    return http.post(url, post);
}

export default{
    getPosts,
    getPostById,
    updatePost,
    newPost
}