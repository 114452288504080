import React, { useState } from "react";
import { Link } from "react-router-dom";
/* antd */
import { Layout, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

interface NavItem {
  id: number;
  icon: any;
  name: string;
  path: string;
  subItems: NavItem[];
}

const initNavItems: NavItem[] = [
  {
    id: 1,
    icon: <DesktopOutlined />,
    name: "Dashboard",
    path: "/app",
    subItems: [],
  },
  {
    id: 2,
    icon: <DesktopOutlined />,
    name: "Products",
    path: "/app/products",
    subItems: [],
  },
  {
    id: 3,
    icon: <DesktopOutlined />,
    name: "Users",
    path: "/app/users",
    subItems: [],
  },
  {
    id: 4,
    icon: <DesktopOutlined />,
    name: "Posts",
    path: "/app/posts",
    subItems: [],
  },
  {
    id: 5,
    icon: <DesktopOutlined />,
    name: "Counter",
    path: "/app/counter",
    subItems: [],
  },
  {
    id: 6,
    icon: <DesktopOutlined />,
    name: "Officer",
    path: "/app/officers",
    subItems: [],
  },
  {
    id: 7,
    icon: <DesktopOutlined />,
    name: "Teacher",
    path: "/app/teacher",
    subItems: [],
  },
  {
    id: 8,
    icon: <DesktopOutlined />,
    name: "Student",
    path: "/app/Student",
    subItems: [],
  },
  {
    id: 9,
    icon: <FileOutlined />,
    name: "Subject",
    path: "/app/subject/list",
    subItems: [],
} ,

];

const SiderNav = () => {
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [navItems, setNavItems] = useState(initNavItems);

  return (
    <Sider
      className="side-nav"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed((prev) => !prev)}
    >
      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        defaultOpenKeys={["sub5"]}
      >
        {navItems.map((navItem) =>
          navItem.subItems.length === 0 ? (
            <Menu.Item key={navItem.id} icon={navItem.icon}>
              <Link to={navItem.path}>{navItem.name}</Link>
            </Menu.Item>
          ) : (
            <SubMenu
              key={`sub+${navItem.id}`}
              icon={navItem.icon}
              title={navItem.name}
            >
              {navItem.subItems.map((subItem) => (
                <Menu.Item key={subItem.id}>
                  <Link to={subItem.path}>{subItem.name}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          )
        )}
      </Menu>
      {/* <Menu defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item
          key="1"
          icon={<PieChartOutlined />}
          style={{ marginBottom: "20px" }}
        >
          <Link to="/">Dashboard</Link>
        </Menu.Item>

        <Menu.Item key="2" icon={<DesktopOutlined />}>
          <Link to="/Products">Products</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<DesktopOutlined />}>
          <Link to="/Customers">Customers</Link>
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<DesktopOutlined />}
          style={{ marginBottom: "20px" }}
        >
          <Link to="/Orders">Orders</Link>
        </Menu.Item>

        <SubMenu key="sub1" icon={<UserOutlined />} title="User">
          <Menu.Item key="5">Tom</Menu.Item>
          <Menu.Item key="6">Bill</Menu.Item>
          <Menu.Item key="7">Alex</Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
          <Menu.Item key="8">Team 1</Menu.Item>
          <Menu.Item key="9">Team 2</Menu.Item>
        </SubMenu>
        <Menu.Item key="10" icon={<FileOutlined />}>
          Files
        </Menu.Item>
      </Menu> */}
    </Sider>
  );
};

export default SiderNav;
