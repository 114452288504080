
const initStudents = [
  {
    id: 1,
    code: "01",
    name: "Apple",
    lastname: "red",
    address: "chiang mai",
    birthday: "1/1/2015",
    isActive: true,
  },
  {
    id: 2,
    code: "02",
    name: "Banana",
    lastname: "yellow",
    address: "chiang rai",
    birthday: "1/5/2015",
    isActive: true,
  },
  {
    id: 3,
    code: "03",
    name: "Carrot",
    lastname: "orange",
    address: "bangkok",
    birthday: "1/12/2016",
    isActive: true,
  },
  {
    id: 4,
    code: "04",
    name: "Durian",
    lastname: "gold",
    address: "lamphun",
    birthday: "1/3/2018",
    isActive: true,
  },
];

const genId = () => {
  const prices = initStudents.map((item) => item.id);
  let lastId = prices.slice(prices.length - 1, prices.length)[0];
  let newId = lastId + 1;
  return newId;
}

const getStudents = (isActive: boolean) => {
  const datas = initStudents.filter((item) => item.isActive === isActive);
  return datas;
};

const getStudentById = (id: number) => {
  const datas = initStudents.filter((item) => item.id === id);
  return datas[0];
};

const updateStudent = (student: any) => {
  const data = getStudentById(student.id);
  data.name = student.name;
  data.lastname = student.lastname;
  data.code = student.code;
  data.address = student.address;
  data.birthday = student.birthday;
  data.isActive = student.isActive;
  return data;
}

const addStudent = (student: any) => {
  const newStudent = { ...student }
  newStudent.id = genId();
  initStudents.push(newStudent);
  return newStudent;
}

const isValidCode = (code: string) => {
  const codes = initStudents.map((item) => item.code);
  for (var i = 0; i < codes.length; i++) {
    if (codes[i] === code) {
      return true
    }
  }
  return false
}


export default { getStudents, getStudentById, addStudent, updateStudent, genId, isValidCode };
