import React, { useState, useEffect } from "react";
import { Button, Col, Row, Space, Typography } from "antd";
// import {
//   CloseCircleOutlined,
//   // CaretRightOutlined,
//   // CaretLeftOutlined,
//   // PlusOutlined,
// } from "@ant-design/icons";

interface IProps {
  initial?: number;
  min?: number;
  max: number;
  step?: number;
  reset?: boolean;
  counterItems?: any;
  onChange?: (value: number) => void;
  handleDelete?: (id: number) => void;
}

const Counter: React.FC<IProps> = (props) => {
  const [counter, setCounter] = useState<number>(0);
  const [disableMinus, setDisableMinus] = useState<boolean>(false);
  const [disablePlus, setDisablePlus] = useState<boolean>(false);

  const {
    min = 0,
    max,
    step = 1,
    onChange,
    initial = 0,
    reset,
    counterItems,
    handleDelete,
  } = props;

  useEffect(() => {
    setCounter(initial);
  }, []);

  useEffect(() => {
    setDisableMinus(counter <= min);
    setDisablePlus(counter >= max);
    if (onChange !== undefined) onChange(counter);
  }, [counter]);

  useEffect(() => {
    reset && setCounter(0);
  }, [reset]);

  const minus = () => {
    setCounter((x) => x - step);
  };

  const plus = () => {
    setCounter((x) => x + step);
  };

  return (
    <>
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Space>
            <Button
              shape="circle"
              type="primary"
              danger
              onClick={() => minus()}
              disabled={disableMinus}
            >
              -
            </Button>
            <div style={{ marginTop: 10 }}>
              <Typography.Title level={1}>{counter}</Typography.Title>
            </div>
            <Button
              shape="circle"
              type="primary"
              onClick={() => plus()}
              disabled={disablePlus}
            >
              +
            </Button>
            {/* {counterItems.length === 1 ? (
              <div></div>
            ) : (
              <div>
                <CloseCircleOutlined
                  onClick={() =>
                    handleDelete !== undefined && handleDelete(counter)
                  }
                  style={{ color: "red", cursor: "pointer" }}
                />
              </div>
            )} */}
          </Space>
        </Col>
        <Col span={2}></Col>
      </Row>
    </>
  );
};

export default Counter;
