import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "src/views/Home";
import LoginForm from "src/views/LoginForm";
import AppRoute from "./views/AppRoute"

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}    />
        <Route exact path="/login" component={LoginForm} />
        <Route path="/app" component={AppRoute} />
        {/* <Route path="/products" component={Products} />
        <Route path="/tests/users"  render={(renderProps) => (<AppLayout><UserList /></AppLayout>)} /> */}
        {/* <Redirect path="/*" to="/" /> */}
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
};

export default App;
