import React, { useState } from "react";

import PageHeader from "src/layouts/PageHeader";
import { useHistory } from "react-router-dom";
import { Button, Space } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { Content } from "antd/lib/layout/layout";
import SubjectForm from "./SubjectForm";

const NewSubject: React.FC = () => {
  const [trigger, setTrigger] = useState<boolean>(false);
  const history = useHistory();
  return (
    <>
      <PageHeader title="New post">
        <Space>
          <Button
            danger
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => setTrigger(true)}
          >
            Save
          </Button>
          <Button
            size="small"
            // type="primary"
            icon={<CloseCircleOutlined />}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Space>
      </PageHeader>
      <Content>
        <SubjectForm id={0} trigger={trigger} setTrigger={setTrigger} />
      </Content>
    </>
  );
};

export default NewSubject;
