import type { AppThunk } from "../index";
import { SessionUser } from "./types";
import {
  setSessionUser as setSessionUserAction,
  getSessionUserFromLocal as getSessionUserFromLocalAction,
} from "./slice";

export const getSessionUserFromLocal =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const username = localStorage.getItem("sessionUser.username");
    const email = localStorage.getItem("sessionUser.email");

    console.log("username, email", username, email);
    const sessionUser: SessionUser = {
      username: username as string,
      email: email as string,
    };
    dispatch(getSessionUserFromLocalAction(sessionUser));
  };

export const setSessionUser =
  (sessionUser: SessionUser): AppThunk =>
  async (dispatch): Promise<void> => {
    localStorage.setItem("sessionUser.username", sessionUser.username);
    localStorage.setItem("sessionUser.email", sessionUser.email);

    console.log("action::setSessionUser", sessionUser);
    dispatch(setSessionUserAction(sessionUser));
  };
