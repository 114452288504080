import React, { useEffect, useState } from 'react';
import { Button, Layout, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Post } from 'src/domain/postType';
import postService from 'src/services/postService';
import { Subject } from 'src/domain/subjectType';
import subjectService from 'src/services/subjectService';





const columns = [
    {
        title: "Code",
        dataIndex: "id",
        key: "id",
        
    },
    {
        title: "Subject",
        dataIndex: "name",
        key: "name",
        render: (value: string,item:Subject,index:number) => <Link to={`/app/subject/view/${item.id}`}>{value}</Link>,
    },
    {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Class",
        dataIndex: "level",
        key: "level",
        
    },

];


const ListSubject = () => {

const [subject,setSubject] = useState([])
  


    useEffect(() => {
        // setSubject(data);
        let data:any = subjectService.getSubject()
        console.log(data)
                setSubject(data)
          
    },[]);

    const history = useHistory();
    const { Content } = Layout;
    return (
        <div>
            <PageHeader title="ListSubject">

                <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => { history.push("/app/subject/new") }} 
                    >New
                    </Button>


            </PageHeader>
            <Content className="app-page-content">
                <Table 
               rowKey={(item:Subject) => item.id}
               columns={columns} 
               dataSource={subject} 
               size="small" />
               
            </Content>
        </div>
    )
}

export default ListSubject;
