import React, { useState, useEffect } from "react";
import PageHeader from "src/layouts/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Spin, Table } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import { Post } from "src/domain/postType";
import postService from "src/services/postService";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "userName",
    key: "userName",
    width: 90,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (value: string, item: Post, index: number) => (
      <Link to={`/app/posts/view/${item.id}`}>{value}</Link>
    ),
  },
  {
    title: "Body",
    dataIndex: "body",
    key: "body",
  },
];

const users = [
  { id: 1, name: "Johny Dave" },
  { id: 2, name: "John Doe" },
  { id: 3, name: "The Rock" },
  { id: 4, name: "Na-dech Cumiya" },
  { id: 5, name: "Yaya Urusya" },
];

const ListPosts: React.FC = () => {
  const [post, setPost] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await postService.getPosts();
      let _post = res.data.slice(0, 50);
      for (let i = 0; i < _post.length; i++) {
        // for (let j = 0; j < users.length; j++) {
        //   if (_post[i].userId === users[j].id) {
        //     _post[i].userName = users[j].name;
        //     setPost(_post);
        //   }
        // }
        let x: any = users.filter((item: any) => _post[i].userId === item.id);
        _post[i].userName = x[0].name;
        setPost(_post);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "20%",
          }}
        >
          <Spin size="large" tip="Loading..." />
        </div>
      ) : (
        <>
          <PageHeader title="Post List">
            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => history.push("/app/posts/new")}
            >
              New
            </Button>
          </PageHeader>
          <Content className="app-page-content">
            <div>
              <Table
                columns={columns}
                dataSource={post}
                size="small"
                rowKey={(item: Post) => item.id}
              />
            </div>
          </Content>
        </>
      )}
    </>
  );
};

export default ListPosts;
