import React, { useState, useEffect } from "react";
import PageHeader from "src/layouts/PageHeader";
import { Button, Switch, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import { Student } from "src/domain/studentType";
import studentService from "src/services/studentService";
import Flex from "src/components/container/Flex";

const columns = [
  { title: "Code", dataIndex: "code", key: "code" },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: string, item: Student, index: number) => (
      <Link to={`/app/student/view/${item.id}`}>{value}</Link>
    ),
  },
  { title: "LastName", dataIndex: "lastname", key: "lastname" },
  { title: "Address", dataIndex: "address", key: "address" },
  { title: "Birthday", dataIndex: "birthday", key: "birthday" },
];

const StudentList: React.FC<Student> = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [active, setActive] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const students = studentService.getStudents(active);
    setStudents(students);
    // return () => { };
  }, []);

  useEffect(() => {
    const students = studentService.getStudents(active);
    setStudents(students);
  }, [active]);

  const activeOnChange = (checked: boolean) => {
    setActive(checked);
  };

  return (
    <>
      <PageHeader title="Student List">
        <Button
          size="small"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/student/new")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Flex justifyContent="end" alignItems="center">
          <Switch
            style={{ margin:"10px"}}
            defaultChecked={active}
            onChange={activeOnChange}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        </Flex>
        <div>
          <Table
            columns={columns}
            dataSource={students}
            size="small"
            rowKey={(item: Student) => item.name}
          />
        </div>
      </Content>
    </>
  );
};

export default StudentList;
