import React, { useState } from "react";
/* 
    antd 
*/
import { Layout, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

/*
   local
*/
import HeaderNav from "./HeaderNav";
import SiderNav from "src/layouts/SiderNav";

interface IProps {
  children?: React.ReactNode;
}

const AppLayout: React.FC<IProps> = (props) => {
  const { Header, Content, Sider, Footer } = Layout;
  const { children, ...rest } = props;
  const [collapsed, setCollapsed] = useState(false);

  // console.log("app layout render", props);

  return (
    <Layout>
      <HeaderNav />
      <Layout>
        <SiderNav />
        <Layout className="app-layout">
          <div className="app-content">
            <Content>{children}</Content>
            {/* <Footer>Footer</Footer> */}
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
