import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import StudentForm from './StudentForm';

interface IParams {
    id: string;
}

const EditStudent: React.FC<RouteComponentProps<IParams>> = (porp) => {
    const _id = porp.match.params.id;
    return (
        <div>
             <StudentForm id={Number(_id)} mode="EDIT" />
        </div>
    )
}

export default EditStudent
