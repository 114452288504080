import React from 'react'
import StudentForm from './StudentForm';


const NewStudent = () => {
    return (
        <div>
            <StudentForm id={0} mode='NEW' />
        </div>
    )
}

export default NewStudent
