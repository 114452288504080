import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Statistic,
  Switch,
  Tooltip,
  BackTop,
} from "antd";
import PageHeader from "src/layouts/PageHeader";
import Counter from "./Counter";
import { Content } from "antd/lib/layout/layout";
import {
  PlusOutlined,
  ClearOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";

interface ICounter {
  id: number;
  name: string;
  min: number;
  max: number;
  step: number;
  initialValue: number;
}

const initialCounter: ICounter[] = [
  { id: 0, name: "counter0", min: 0, max: 10, step: 1, initialValue: 0 },
];

const CounterList = () => {
  const [sumation, setSumation] = useState(0);
  const [counterItems, setCounterItems] = useState<ICounter[]>(initialCounter);
  const [reset, setReset] = useState<boolean>(false);
  const [max, setMax] = useState<number>(10);
  const [step, setStep] = useState<number>(1);
  const [begin, setBegin] = useState<number>(0);
  const [limit, setLimit] = useState<number>(2);

  const [swAll, setSwAll] = useState<boolean>(false);
  const [swMax, setSwMax] = useState<boolean>(false);
  const [swStep, setSwStep] = useState<boolean>(false);
  const [swlimit, setSwLimit] = useState<boolean>(false);
  const [swBegin, setSwBegin] = useState<boolean>(false);

  useEffect(() => {
    // sum();
    // const sum = () => {
    setSumation(
      counterItems.reduce(
        (prev: any, curr: any) => prev + curr.initialValue || 0,
        0
      )
    );
    // };
  }, [counterItems]);

  // const sum = () => {
  //   setSumation(
  //     counterItems.reduce(
  //       (prev: any, curr: any) => prev + curr.initialValue || 0,
  //       0
  //     )
  //   );
  // };

  const handleChange = (index: number, value: number) => {
    // console.log(index, value);
    setCounterItems(
      counterItems.map((item: any) =>
        item.id === index ? { ...item, initialValue: value } : item
      )
    );
  };

  const handleOuther = (index: number, func: any) => {
    return (value: number) => func(index, value);
  };

  const handleDelete = (index: number, value: number) => {
    // console.log(index, value);
    // if (counterItems.length === 1) return;
    let x = counterItems.filter((item: any) => item.id !== index);
    setCounterItems(x);
  };

  const handleOuther1 = (index: number, func: any) => {
    return (id: number) => func(index, id);
  };

  const handleAdd = () => {
    let getId = counterItems.length;
    let x = {
      id: getId,
      name: `Counter${getId}`,
      min: 0,
      max,
      step,
      initialValue: begin,
    };
    // console.log(x);
    if (limit === 0) {
      setCounterItems([...counterItems, x]);
      resetInput();
    }
    if (counterItems.length < limit) {
      setCounterItems([...counterItems, x]);
      resetInput();
    }
  };

  const resetInput = () => {
    setMax(10);
    setStep(1);
    setBegin(0);
  };

  const handleClear = () => {
    // setLimit(3);
    // setReset(true);
    // setTimeout(() => {
    //   setReset((prev) => !prev);
    // }, 2000);
    // let x = counterItems.slice(0, 1);
    // console.log(x);
    // console.log(initialCounter);
    // setCounterItems(counterItems.slice(0, 1));
    // setCounterItems(initialCounter);
    window.location.reload();
  };

  const allOnChange = (checked: boolean) => {
    setSwAll(checked);
    setSwMax(checked);
    setSwStep(checked);
    setSwBegin(checked);
    setSwLimit(checked);
  };

  const maxOnChange = (checked: boolean) => {
    setSwMax(checked);
  };

  const stepOnChange = (checked: boolean) => {
    setSwStep(checked);
  };

  const beginOnChange = (checked: boolean) => {
    setSwBegin(checked);
  };

  const limitOnChange = (checked: boolean) => {
    setSwLimit(checked);
  };

  return (
    <>
      <PageHeader title="Counter list" />
      <Content>
        <Row justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
          <Col span={22}>
            <Input.Group compact style={{ textAlign: "center" }}>
              <Input
                disabled={swMax}
                prefix="Max"
                type="number"
                value={max}
                onChange={(e) => setMax(Number(e.target.value))}
                style={{ width: "10%" }}
                placeholder="Max"
              />
              <Input
                disabled={swStep}
                prefix="Step"
                type="number"
                value={step}
                onChange={(e) => setStep(Number(e.target.value))}
                style={{ width: "10%" }}
                placeholder="Step"
              />
              <Input
                disabled={swBegin}
                prefix="Begin"
                type="number"
                value={begin}
                onChange={(e) => setBegin(Number(e.target.value))}
                style={{ width: "10%" }}
                placeholder="Begin"
              />
              <Input
                disabled={swlimit}
                prefix="Limit"
                type="number"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
                style={{ width: "10%" }}
                placeholder="Limit"
              />

              <Tooltip title="ADD">
                <Button onClick={() => handleAdd()} type="primary">
                  <PlusOutlined style={{ fontSize: 16 }} />
                </Button>
              </Tooltip>

              <Tooltip title="CLEAR">
                <Button onClick={() => handleClear()} type="primary" danger>
                  <ClearOutlined style={{ fontSize: 16 }} />
                </Button>
              </Tooltip>
            </Input.Group>
          </Col>
          <Col span={2}>
            <Space direction="vertical">
              <div>
                <Switch
                  // checked={swAll}
                  style={{ width: 70 }}
                  defaultChecked={swMax}
                  onChange={allOnChange}
                  checkedChildren="All"
                  unCheckedChildren="All"
                />
              </div>
              <div>
                <Switch
                  // checked={swAll}
                  disabled={swAll}
                  style={{ width: 70 }}
                  defaultChecked={swMax}
                  onChange={maxOnChange}
                  checkedChildren="Max"
                  unCheckedChildren="Max"
                />
              </div>
              <div>
                <Switch
                  // checked={swAll}
                  disabled={swAll}
                  style={{ width: 70 }}
                  defaultChecked={swStep}
                  onChange={stepOnChange}
                  checkedChildren="Step"
                  unCheckedChildren="Step"
                />
              </div>
              <div>
                <Switch
                  // checked={swAll}
                  disabled={swAll}
                  style={{ width: 70 }}
                  defaultChecked={false}
                  onChange={beginOnChange}
                  checkedChildren="Begin"
                  unCheckedChildren="Begin"
                />
              </div>
              <div>
                <Switch
                  // checked={swAll}
                  disabled={swAll}
                  style={{ width: 70 }}
                  defaultChecked={false}
                  onChange={limitOnChange}
                  checkedChildren="Limit"
                  unCheckedChildren="Limit"
                />
              </div>
            </Space>
          </Col>
        </Row>
        <div>
          {counterItems.map((item: any) => (
            <Counter
              key={item.id}
              min={item.min}
              max={item.max}
              step={item.step}
              onChange={handleOuther(item.id, handleChange)}
              initial={item.initialValue}
              reset={reset}
              counterItems={counterItems}
              handleDelete={handleOuther1(item.id, handleDelete)}
            />
          ))}

          <BackTop>
            <UpCircleOutlined style={{ fontSize: 26 }} />
          </BackTop>
        </div>
        <div style={{ marginTop: 20 }}>
          {counterItems.length === 0 ? null : (
            <Row justify="center">
              <Col span={22}>
                <Statistic
                  valueStyle={{ fontSize: 90, fontWeight: "bold" }}
                  title="SUMATION"
                  value={sumation}
                  style={{ textAlign: "center" }}
                />
              </Col>
              <Col span={2}></Col>
            </Row>
          )}
        </div>
      </Content>
    </>
  );
};

export default CounterList;
