import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Student } from "src/domain/studentType";
import {
  Col,
  Form,
  Input,
  Row,
  Typography,
  message,
  PageHeader,
  Button,
  Layout,
} from "antd";
import { showMessage } from "src/utils";
import { SaveOutlined, RollbackOutlined } from "@ant-design/icons";
import studentService from "src/services/studentService";

interface IProps {
  id: number;
  mode: string;
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const StudentForm: React.FC<IProps> = (props) => {
  const _id = props.id;
  const mode = props.mode;
  const history = useHistory();
  const [student, setStudent] = useState<Student>({} as Student);
  const [form] = Form.useForm();

  useEffect(() => {
    if (mode === "EDIT") {
      const res = studentService.getStudentById(_id);
      setStudent(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: student?.name,
      lastname: student?.lastname,
      address: student?.address,
      code: student?.code,
      birthday: student?.birthday,
      isActive: student?.isActive,
    });
  }, [form, student]);

  const onFinish = async (values: any) => {
    if (mode === "NEW" && studentService.isValidCode(values.code)) {
      message.warning('Code "' + values.code + '" is used', 2);
      return;
    }
    const _student = setStudentData(
      values,
      mode === "NEW" ? studentService.genId() : _id,
      mode === "NEW" ? true : values.isActive
    );
    try {
      if (mode === "NEW") {
        await studentService.addStudent(_student);
        history.replace("/app/student/list/");
        message.success("Success, Create Complete", 1.5);
      } else {
        await studentService.updateStudent(_student);
        history.replace("/app/student/view/" + _id);
        message.success("Success, Update Complete", 1.5);
      }
    } catch (err) {
      showMessage(err);
    }
  };

  const setStudentData = (values: any, _id: number, active: boolean) => {
    const _student: Student = {
      id: _id,
      name: values.name,
      lastname: values.lastname,
      birthday: values.birthday,
      address: values.address,
      code: values.code,
      isActive: active,
    };
    return _student;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <PageHeader
        title={mode + " Student"}
        extra={[
          <Button
            size="small"
            type="primary"
            danger
            icon={<SaveOutlined />}
            onClick={() => form.submit()}
          >
            Save
          </Button>,
          <Button
            size="small"
            type="default"
            icon={<RollbackOutlined />}
            onClick={() => history.replace("/app/student/list/")}
          >
            Cancle
          </Button>,
        ]}
      />

      <Layout.Content className="app-page-content">
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row style={{ margin: "10px 10px" }}>
            <Col span="24">
              <Form.Item wrapperCol={{ offset: 3, span: 10 }}>
                <Typography.Title level={4}>
                  General Information
                </Typography.Title>
              </Form.Item>

              <Form.Item
                {...layout}
                label="Code"
                name="code"
                rules={[
                  { required: true, message: "Please input code!" },
                  {
                    min: 1,
                    max: 8,
                    message: "Name must be between 1 and 8 characters",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                {...layout}
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input name!" },
                  {
                    min: 1,
                    max: 100,
                    message: "Name must be between 1 and 100 characters",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                {...layout}
                label="Lastname"
                name="lastname"
                rules={[
                  { required: true, message: "Please input lastname!" },
                  {
                    min: 1,
                    max: 100,
                    message: "Username must be between 1 and 100 characters",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...layout}
                label="Address"
                name="address"
                rules={[{ required: true, message: "Please input address!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...layout}
                label="Birthday"
                name="birthday"
                rules={[
                  { type: "date", message: "The input is not valid date!" },
                  { required: true, message: "Please input birthday!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </>
  );
};

export default StudentForm;
