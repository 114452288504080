import React, { useState, useEffect } from "react";
import { Button, Divider, Table, Tag } from "antd";
import PageHeader from "src/layouts/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";

import teacherService from "../../services/teacher/teacherService";
import { Teacher } from "src/domain/teacherType";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: string, item: Teacher, index: number) => (
      <Link to={`/app/teacher/view/${item.id}`}>{value}</Link>
    ),
  },
  {
    title: "Lastname",
    dataIndex: "lastname",
    key: "lastname",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "Education",
    dataIndex: "education",
    key: "education",
  },

];

const TeacherLists: React.FC<Teacher> = () => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const history = useHistory();

  useEffect(() => {
    setTeachers( teacherService
      .getTeachers());
      // .then((res) => {
      //   console.log(res.data);
      //   setTeachers(res.data);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    return () => {};
  }, []);

  return (
    <>
      <PageHeader title="Teacher List">
        <Button
          size="small"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/teacher/new")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div>
          <Table
            columns={columns}
            dataSource={teachers}
            size="small"
            rowKey={(item: Teacher) => item.name}
          />
        </div>
      </Content>
    </>
  );
};

export default TeacherLists;
