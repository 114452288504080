import React from "react";

import { Avatar, Badge } from "antd";

import { BellOutlined, SettingOutlined } from "@ant-design/icons";
import { Header } from "antd/lib/layout/layout";

const HeaderNav = () => {
  return (
    <Header className="app-header">
      <div
        className="logo d-flex flex-row justify-content-start align-items-center"
        style={{ width: "200px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <img src="/app-logo.png" width="auto" height="25px" />
        </div>
        <h4 className="title">Header</h4>
      </div>
      <div className="nav-right">
        <BellOutlined className="nav-icon" style={{ marginRight: "20px" }} />

        <SettingOutlined className="nav-icon" style={{ marginRight: "20px" }} />
        <Avatar size={35} src="/user-thumbnail.png" />
      </div>
    </Header>
  );
};

export default HeaderNav;
