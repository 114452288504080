import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import userService from "../../services/userService";
import { User } from "src/domain/userType";
import { Button, Descriptions } from "antd";
import { EditOutlined } from "@ant-design/icons";

interface IParams {
  id: string;
}

const ViewUser: React.FC<RouteComponentProps<IParams>> = (props) => {
  const [user, setUser] = useState<User>({} as User);
  const history = useHistory();

  useEffect(() => {
    let id: number = Number(props.match.params.id);
    userService
      .getUserById(id)
      .then((res) => {
        console.log(res.data);
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const loadUser = () => {};

  return (
    <>
      <PageHeader
        title={"User: " + user.name}
        onClose={() => history.push("/app/users/list")}
      >
        <Button
          size="small"
          // type="default"
          icon={<EditOutlined />}
          onClick={() => history.push(`/app/users/edit/${user.id}`)}
        >
          Edit
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div style={{ padding: "0px 20px" }}>
          <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            title="Genral Information"
            size="middle"
            bordered
          >
            <Descriptions.Item label="Name" span={2}>
              {user.name}
            </Descriptions.Item>
            <Descriptions.Item label="Username">
              {user.username}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
            <Descriptions.Item label="Address" span={2}>
              {user.address && user.address.street}
              <br />
              {user.address && user.address.suite}
              <br />
              {user.address && user.address.city}
              <br />
              {user.address && user.address.zipcode}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">{user.phone}</Descriptions.Item>
            <Descriptions.Item label="Website">
              {user.website}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            title="Compay"
            size="middle"
            bordered
          >
            <Descriptions.Item label="Name" span={1}>
              {user.company && user.company.name}
            </Descriptions.Item>
            <Descriptions.Item label="Phrase">
              {user.company && user.company.catchPhrase}
            </Descriptions.Item>
            <Descriptions.Item label="Business">
              {user.company && user.company.bs}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Content>
    </>
  );
};

export default ViewUser;
