import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Col, Form, Input, message, Row, Typography } from "antd";

import userService from "../../services/userService";
import { User } from "src/domain/userType";
import { showMessage } from "src/utils";

interface IProps {
  id: number;
  trigger: any;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const UserForm: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  const [user, setUser] = useState<User>({} as User);
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    loadUser();
    return () => {};
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: user.name,
      username: user.username,
      email: user.email,
      phone: user.phone,
      website: user.website,

      street: user.address?.street,
      suite: user.address?.suite,
      city: user.address?.city,
      zipcode: user.address?.zipcode,

      companyName: user.company?.name,
      phrase: user.company?.catchPhrase,
      bs: user.company?.bs,
    });
    return () => {};
  }, [user]);

  const loadUser = async () => {
    // const id: number = Number(props.match.params.id);
    const res = await userService.getUserById(id);
    setUser(res.data);
  };

  const onFinish = async (values: any) => {
    // console.log("Succes :", values);
    const _user: User = {
      id: id,
      name: values.name,
      username: values.username,
      email: values.email,
      website: values.website,
      phone: values.phone,
      address: {
        street: values.street,
        suite: values.suite,
        city: values.city,
        zipcode: values.zipcode,
      },
      company: {
        name: values.companyName,
        catchPhrase: values.phrase,
        bs: values.bs,
      },
    };

    if (id === 0) {
      try {
        const res = await userService.postUser(_user);
        console.log(res);
        history.replace("/app/users/list");
        message.success("New user Complete!", 1.5);
        setTrigger(false);
      } catch (err) {
        showMessage("Something went wrong!");
        setTrigger(false);
      }
    } else if (id !== 0) {
      try {
        const res = await userService.updateUser(_user);
        history.replace("/app/users/view/" + id);
        message.success("Update Complete!", 1.5);
        setTrigger(false);
      } catch (err) {
        showMessage("Something went wrong!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        // initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row style={{ marginBottom: "3em" }}>
          <Col span={24} style={{ marginTop: "2em" }}>
            <Form.Item
              wrapperCol={{ offset: 4, span: 8 }}
              // rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Typography.Title level={3}>General Information</Typography.Title>
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input your name!" },
                {
                  min: 5,
                  max: 50,
                  message: "Please input your name between 5 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
                {
                  min: 5,
                  max: 50,
                  message: "Please input your name between 5 - 50 character",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              // rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Website"
              name="website"
              // rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} offset={2} style={{ marginTop: "2em" }}>
            <Form.Item
              wrapperCol={{ offset: 4, span: 8 }}
              // rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Typography.Title level={3}>Address</Typography.Title>
            </Form.Item>

            <Form.Item
              label="Street"
              name="street"
              // rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Suite"
              name="suite"
              // rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please input your city!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Zipcode"
              name="zipcode"
              rules={[
                { required: true, message: "Please input your zipcode!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} style={{ marginTop: "2em" }}>
            <Form.Item
              wrapperCol={{ offset: 4, span: 8 }}
              // rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Typography.Title level={3}>Company</Typography.Title>
            </Form.Item>

            <Form.Item
              // wrapperCol={{ span: 14 }}
              label="Name"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please input your company name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Phrase"
              name="phrase"
              // rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Business"
              name="bs"
              // rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UserForm;
