import http from "../lib/http";

const url = "https://jsonplaceholder.typicode.com/users";
const getUsers = () => {
    return http.get(url);
};

const getUserById = (id: number) => {
    return http.get(`${url}/${id}`);
}

const updateUser = (user: any) => {
    return http.put(url + "/" + user.id, user);
}

const postUser = (user: any) => {
    return http.post(url, user);
}

export default {
    getUsers,
    getUserById,
    updateUser,
    postUser
}