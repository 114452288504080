import React, { useState } from "react";
import PageHeader from "src/layouts/PageHeader";
import { useHistory } from "react-router-dom";

import { Button, Form, Space } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import TeacherForm from "./TeacherForm";
import { Content } from "antd/lib/layout/layout";

const NewTeacher: React.FC = () => {
  const [trigger, setTrigger] = useState<boolean>(false);
  const history = useHistory();

  return (
    <>
      <PageHeader title="New Teacher">
        <Space>
          <Button
            danger
            size="small"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => setTrigger(true)}
          >
            Save
          </Button>
          <Button
            size="small"
            // type="primary"
            icon={<CloseCircleOutlined />}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Space>
      </PageHeader>
      <Content className="app-page-content">
        <TeacherForm id={0} trigger={trigger} setTrigger={setTrigger} />
      </Content>
    </>
  );
};

export default NewTeacher;
